import { useEffect } from 'react';

export default () => {
  useEffect(() => {
    window.location.replace(
      'https://www.youtube.com/watch?v=vZkNtFoTXJ0&t=11s',
    );
  }, []);
  return null;
};
